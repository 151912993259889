<template>
  <div class="w-100">

    <div class="card mb-4">
      <div class="card-header pb-0">
        <h6>Agent details</h6>
      </div>
      <div class="card-body px-4">
        <div class="row">
          <div class="col">
            <ul style="list-style: none" v-if="user">
              <li><span class="font-weight-bold">Name: </span><span>{{user.name}}</span></li>
              <li><span class="font-weight-bold">Email: </span><span>{{user.email}}</span></li>
              <li><span class="font-weight-bold">Password: </span><span>{{user.password_text}}</span></li>
              <li><span class="font-weight-bold">Percentage commission: </span>{{user.percentage * 100}}%<span></span></li>
              <li><span class="font-weight-bold">Status: </span><span>{{user.status?'ACTIVE':'INACTIVE'}}</span></li>
              <li><span class="font-weight-bold">Date registered: </span><span>{{$filters.formatDate(user.created_at)}}</span></li>
            </ul>
            <hr>

          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <label>Name</label>
                <input type="text" class="form-control" v-model="form.name">
                <label>Email</label>
                <input type="text" class="form-control" v-model="form.email">
                <label>Percentage Commission</label>
                <input type="number" class="form-control" v-model="form.percentage">
              </div>
              <div class="col">
                <label>Password</label>
                <input type="text" class="form-control" v-model="form.password">
                <label>Phone</label>
                <input type="number" class="form-control" v-model="form.phone">
                <label>Role</label>
                <select type="text" class="form-control" v-model="form.type">
                  <option value="agent_leads">Agent Leads</option>
                  <option value="agent_ftd">Agent FTD</option>
                  <option value="agent_retention">Agente Retencion</option>
                  <option value="supervisor_ftd">Supervisor FTD</option>
                  <option value="supervisor_retention">Supervisor Retencion</option>
                  <option value="administrator">Administrador del sistema</option>
                </select>
                <label>Status</label>
                <select type="text" class="form-control" v-model="form.status">
                  <option value="true">ACTIVE</option>
                  <option value="false">INACTIVE</option>
                </select>
              </div>
              <button  class="btn bg-gradient-success mt-4 w-100" @click="copyUser"> Copy user data</button>
              <button  class="btn bg-gradient-success w-100" @click="updateProfile"> update user information</button>
            </div>
          </div>
          <div class="col text-center" v-if="user && show_avatar">
            <img v-if="user.url_avatar" :src="user.url_avatar" alt="..." class="img-thumbnail">
            <img v-else
                 style="max-width: 200px"
                 src="https://cdn-icons-png.flaticon.com/512/149/149071.png?w=826&t=st=1681779976~exp=1681780576~hmac=b62bef118355188308be83a13bd11e2887b7caffd786ec75d3c56a581d7fdd48"
                 class="img-thumbnail">
            <input type="file" class="form-control mt-2" @change="selectImage">
            <button  class="btn bg-gradient-success mt-4 w-100" @click="uploadAvatar"> update avatar</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {get, post} from "@/utils/httpRequest";
import uploadFile from "@/utils/uploadFile";
export default {
  name: "index",
  data: () => ({
    user_id: null,
    user: null,
    tabSelected: 'transactions',
    url_avatar: '',
    show_avatar: true,
    form: {
      name: '',
      email: '',
      percentage: 0,
      password: '',
      type: '',
      phone: '',
      status: true
    },

  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getProfile()
  },


  methods: {
    async getProfile(){
      const {data} = await get(`users/find-one?id=${this.user_id}`, null)
      this.user = data
      this.form.status = data.status
      this.form.type = data.type
    },
    async selectImage(e){
      this.url_avatar = e.target.files[0]
    },
    async uploadAvatar(){
      if(this.url_avatar!== ''){
        uploadFile.upload('users/upload-avatar',{file: this.url_avatar, _id: this.user_id})
        this.show_avatar = false
        this.url_avatar = ''
        await this.getProfile()
        this.show_avatar = true
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },
    async updateProfile(){
      if(this.form.name !== '' && this.form.email !== '' && this.form.type !== '' && this.form.percentage !== 0 && this.form.password !== ''){
        this.$swal({
          html: 'Are you sure?',
          showCancelButton: true,
          confirmButtonText: 'YES',
          cancelButtonText: `NO`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.form.percentage = Number((this.form.percentage/100).toFixed(2))
            await post('users/update', {...this.form, _id: this.user_id})
            await this.getProfile()
            this.form = {
              name: '',
              email: '',
              percentage: 0,
              password: '',
              type: '',
              phone: ''
            }
            this.$swal({
              html: 'Process successfully',
              icon: 'success',
              timer: 2000,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        })
      }
    },
    copyUser(){
      this.form = {
        name: this.user.name,
        email: this.user.email,
        percentage: this.user.percentage * 100,
        password: this.user.password_text,
        type: this.user.type,
        phone: this.user.phone,
        status: this.user.status
      }
    }
  }
}
</script>


