<template>
<div class="row">
  <div class="col-lg-3 col-md-4 col-sm-12">
    <ul class="nav flex-column mt-4" id="myTabs">
      <li class="nav-item">
        <a class="nav-link btn btn-outline-primary" aria-current="page" data-bs-toggle="tab" href="#tab10">CREATE SMS TEMPLATE</a>
      </li>
      <li class="nav-item">
        <a class="nav-link btn btn-outline-primary" data-bs-toggle="tab" href="#tab11">SMS TEMPLATES LIST</a>
      </li>
      <li class="nav-item">
        <a class="nav-link btn btn-outline-primary" data-bs-toggle="tab" href="#tab12">SEND SMS USING FORM</a>
      </li>
      <li class="nav-item">
        <a class="nav-link btn btn-outline-primary active" data-bs-toggle="tab" href="#tab13">SEND SMS USING TEMPLATE</a>
      </li>
    </ul>
  </div>
  <div class="col-lg-9 col-md-8 col-sm-12">
    <div class="tab-content">
      <!--      ======================== START TAB 1 =======================-->
      <div class="tab-pane fade" id="tab10" role="tabpanel" aria-labelledby="tab10">
        <label>Title</label>
        <input type="text" class="form-control" v-model="template.title" placeholder="Title">
        <label v-if="$userCan('administrator')">Role</label>
        <select class="form-control" v-model="template.permission" v-if="$userCan('administrator')">
          <option value="all">Todos</option>
          <option value="administrator">Administrador</option>
          <option value="supervisor_retention">Supervisor de retencion</option>
          <option value="supervisor_ftd">Supervisor FTD</option>
          <option value="agent_retention">Agente Retencion</option>
          <option value="agent_ftd">Agente FTD</option>
          <option value="agent_leads">Agente Leads</option>
        </select>
        <label>Message.</label>
        <br>
        <div class="d-flex justify-content-between">
          <span class="text-danger">Use ${} to set the variables. Example: Hello, ${name}</span>
          <button class="btn btn-outline-primary" @click="copyToClipboard">Copy ${ } to Clipboard</button>
        </div>
        <ckeditor :editor="editor" v-model="template.description" :config="editorConfig"></ckeditor>
        <button class="btn d-block btn-outline-primary w-100 mt-2" @click="saveTemplate">Save template</button>
      </div>

      <!--      ======================== END TAB 1 =======================-->
      <!--      ======================== START TAB 2 =======================-->
      <div class="tab-pane fade" id="tab11" role="tabpanel" aria-labelledby="tab11">
        <div class="table-responsive">
          <table class="table table-bordered table-sm table-striped">
            <thead>
            <tr>
              <th>Title</th>
              <th>Role</th>
              <th>Text</th>
              <th>Options</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in arrayTemplates" :key="index">
              <td class="text-uppercase text-xs font-weight-bold mb-0">{{item.title}}</td>
              <td class="text-uppercase text-xs font-weight-bold mb-0">{{item.permission}}</td>
              <td class="text-uppercase text-xs font-weight-bold mb-0 ">
                <div class="limited-width_30">
                  {{item.description}}
                </div>
              </td>
              <td class="text-uppercase text-xs font-weight-bold mb-0">
                <button class="btn btn-outline-primary btn-sm" @click="deleteTemplate(item._id)">Delete</button>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-between">
            <div>
              <p class="text-xs font-weight-bold mb-0">total rows: {{pagination.totalRows}}</p>
            </div>
            <div>
              <v-pagination
                  v-model="pagination.page"
                  :pages="pagination.pages"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="reloadPaginate"
              />
            </div>
          </div>
        </div>
      </div>
      <!--      ======================== END TAB 2 =======================-->
      <!--      ======================== START TAB 3 =======================-->
      <div class="tab-pane fade show" id="tab12" role="tabpanel" aria-labelledby="tab12">
          <div class="row">
            <div class="col">
              <input class="form-control" placeholder="ingrese el nombre del usuario destino" v-model="sms.user">
              <input class="form-control mt-1" placeholder="ingrese numero destino. Usar codigo pais. Eje: (+58)" v-model="sms.to">
            </div>
            <div class="col">
              <textarea class="form-control" placeholder="ingrese message" rows="6" v-model="sms.message"></textarea>
              <button class="btn btn-success w-100 mt-1" @click="sendSmsOld">Enviar SMS</button>
            </div>
          </div>
      </div>
      <!--      ======================== END TAB 3 =======================-->
      <!--      ======================== START TAB 4 =======================-->
      <div class="tab-pane fade show active" id="tab13" role="tabpanel" aria-labelledby="tab13">
        <label>Choice SMS template</label>
        <select class="form-control" @change="selectTemplate($event.target.value)">
          <option value="">Choice an option</option>
          <option :value="item._id" v-for="item in arrayTemplates" :key="item.id">{{item.title}}</option>
        </select>
        <hr>
        <div v-if="template_selected !== '' && inputFields.length > 0">
          <label>Template:</label>
          <p disabled v-html="template_selected.description"></p>
          <label>Phone number</label>
          <input class="form-control mt-1" placeholder="Enter phone number" v-model="sms.to">
          <div v-for="(field, index) in inputFields" :key="index">
            <label>{{ getFieldLabel(field.variable) }}</label>
            <input class="form-control" v-model="field.value" :class="{ 'invalid': field.value === '' }">
            <span v-if="field.value === ''" class="error-message">Este campo es requerido</span>
          </div>
          <hr>
          <span>PREVIEW SMS TO SEND:</span>
          <br>
          <span>To: {{sms.to}}</span>
          <br>
          <div v-html="filledTemplate"></div>
          <hr>
          <button class="btn btn-outline-primary w-100 mt-2"  @click="fillTemplate">PREVIEW</button>
          <button class="btn btn-success w-100 mt-1" @click="sendSms">Enviar SMS</button>
        </div>
      </div>
      <!--      ======================== END TAB 4 =======================-->
    </div>
  </div>

</div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {get, post} from "@/utils/httpRequest";
import {decrypt} from "@/utils/encrypt";

export default {
  data: () => ( {
    pagination: {
      searchTimer: null,
      page: 1,
      pages: 0,
      limit: 20,
      search: '',
      totalRows: 0
    },
    template_selected: '',
    editor: ClassicEditor,
    editorData: '',
    editorConfig: {
      language: 'es',
      rows: 10,
      height: 500,
      toolbar: {
        items: [
          'bold',
          'italic',
          'link',
          'undo',
          'redo'
        ]
      },
    },
    arrayTemplates: [],
    template: {
      permission: 'all',
      title: '',
      description: '',
    },
    inputFields: [],
    filledTemplate: '',
    sms: {
      user: '',
      to: '',
      message: ''
    }
  }),
  computed: {
    areFieldsEmpty() {
      return this.inputFields.some(field => field.value === '');
    }
  },
  mounted() {
    this.getTemplates();
  },
  methods: {
    async saveTemplate() {
      if(!this.$userCan('administrator')){
        const store = localStorage.getItem('payment-admin-type');
        this.template.permission = decrypt(store);
      }
      if (this.template.title !== '' && this.template.description !== '' && this.containsPlaceholder(this.template.description)) {
        await post("sms/create-template", {...this.template});
        this.template = {
          permission: 'all',
          title: '',
          description: '',
        }
        await this.getTemplates();
        await this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        })
      }else{
        this.$swal({
          icon: 'error',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
          html: 'Please fill all fields or the message must contain a ${variable_name} variable',
        })
      }
    },
    async deleteTemplate(id) {
      await this.$swal({
        showCancelButton: true,
        confirmButtonText: 'YES',
        cancelButtonText: `NO`,
        html: 'Are you sure?'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post("sms/delete-template", {_id: id});
          await this.getTemplates();
        }
      })
    },
    async getTemplates() {
     const {data} =  await get(`sms/get-templates?page=${this.pagination.page}&limit=${this.pagination.limit}&search=${this.pagination.search}`);
      this.arrayTemplates = data.docs
      this.pagination.totalRows = data.count
      this.pagination.page = data.page
      this.pagination.pages = data.pages
    },
    async selectTemplate(value) {
      this.template_selected = ''
      this.inputFields = []
      this.filledTemplate = ''
      this.sms = {
        user: '',
        to: '',
        message: ''
      }
      if (value !== '') {
        this.template_selected = this.arrayTemplates.find(item => item._id === value);
        this.parseTemplate()
      }
    },
     parseTemplate() {
      const regex = /\${(.*?)}/g;
      const matches = this.template_selected.description.match(regex);
      if (matches) {
        this.inputFields = matches.map(match => ({
          variable: match,
          value: ''
        }));
      }
    },
    getFieldLabel(variable) {
      return variable.replace(/\${|}/g, '');
    },
     async fillTemplate() {
      if (this.areFieldsEmpty) {
        return;
      }

      let filledTemplate = this.template_selected.description;
      for (const field of this.inputFields) {
        filledTemplate = filledTemplate.replace(field.variable, field.value);
      }
      this.filledTemplate = filledTemplate;
    },
    containsPlaceholder(text) {
      const regex = /\${.*?}/;
      return regex.test(text);
    },
    async sendSms(){
      await this.fillTemplate()
      this.sms.message = this.filledTemplate
      if(this.sms.to !== '' && this.sms.message !== ''){
        const {data} = await post('sms/send-sms',{to: this.sms.to, message: this.sms.message})
        await post('sms/create-sms',{...this.sms, response: data})
        await this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        }).then(() => {
          window.location.reload();
        });
      }else{
        await this.$swal({
          html: 'Please fill all fields or the message must contain a ${} variable',
          icon: 'error',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        })
      }
    },
    async sendSmsOld(){
      if(this.sms.to !== '' && this.sms.message !== ''){
        const {data} = await post('sms/send-sms',{to: this.sms.to, message: this.sms.message})
        await post('sms/create-sms',{...this.sms, response: data})
        await this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        }).then(() => {
          window.location.reload();
        });
      }
    },
    reloadPaginate(page){
      this.pagination.page = page
      this.getSms()
    },
    copyToClipboard() {
      const content = "${}";
      const input = document.createElement("input");
      input.value = content;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
    },
  }
};

</script>


<style scoped>
.invalid {
  border: 1px solid red;
}
.error-message {
  color: red;
}
</style>
