<template>
  <table class="table table-bordered align-items-center table-sm mt-4">
    <thead>
    <tr>
      <td colspan="4">
        <h6>Sales List (Internal)</h6>
      </td>
    </tr>
    <tr>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Description</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Quantity</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Amount</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Options</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(item, key) in arrayTransactions" :key="key">
      <td>
        <p class="text-xs font-weight-bold mb-0">{{item.description}}</p>
      </td>
      <td>
        <p class="text-xs font-weight-bold mb-0">{{item.quantity}}</p>
      </td>
      <td>
        <p class="text-xs font-weight-bold mb-0">{{item.amount}}</p>
      </td>
      <td>
        <p class="text-xs font-weight-bold mb-0">{{$filters.formatDate(item.created_at)}}</p>
      </td>
      <td>
        <a :href="item.url_file" target="_blank" type="button" class="btn btn-success btn-sm" v-if="item.url_file">Ver</a>
        <button type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_upload_transaction_file" @click="setTransaction(item)">Upload File</button>
      </td>
    </tr>
    <tr>
      <td colspan="2">Total:</td>
      <td>{{totalTransactions}}</td>
    </tr>
    </tbody>
  </table>
  <div class="modal fade" id="modal_upload_transaction_file" tabindex="-1" aria-labelledby="modal_upload_transaction_file" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Upload Transaction File</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="transaction_selected">
          <input type="file" class="form-control mt-2" @change="selectImage">
          <button  class="btn bg-gradient-success mt-4 w-100" @click="uploadTransactionFile"> upload transaction file</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {get} from "@/utils/httpRequest";
import uploadFile from "@/utils/uploadFile";

export default {
  name: "Transactions",
  data: () => ({
    user_id: null,
    arrayTransactions: [],
    transaction_selected: null,
    url_file: '',
    totalTransactions: 0,
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getTransactions()
  },
  methods:{
    async getTransactions(){
      const {data} = await get(`transactions/by-user?user=${this.user_id}`)
      this.arrayTransactions = data
      this.totalTransactions = data.map(x => x.amount).reduce((prev,next)=> prev+next ,0)
    },
    setTransaction(item){
      this.transaction_selected = item
    },
    async selectImage(e){
      this.url_file = e.target.files[0]
    },
    async uploadTransactionFile(){
      if(this.url_file!== ''){
        await uploadFile.upload('transactions/upload-file',{file: this.url_file, _id: this.transaction_selected._id})
        this.url_file = ''
        await this.getTransactions()
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
        setTimeout(()=>{
          location.reload()
        },1000)
      }
    },
  }
}
</script>

<style scoped>

</style>
