<template>
  <div class="card">
    <div class="card-header">
      <h6>Create new user with access in the platform</h6>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col">
          <label>Name</label>
          <input type="text" class="form-control" v-model="form.name">
          <label>Email</label>
          <input type="text" class="form-control" v-model="form.email">
          <label>Percentage Commission</label>
          <input type="number" class="form-control" v-model="form.percentage">
        </div>
        <div class="col">
          <label>Password</label>
          <input type="text" class="form-control" v-model="form.password">
          <label>Role</label>
          <select type="text" class="form-control" v-model="form.type">
            <option value="agent_leads">Agente Leads</option>
            <option value="agent_ftd">Agente FTD</option>
            <option value="agent_retention">Agente Retencion</option>
            <option value="supervisor_ftd">Supervisor FTD</option>
            <option value="supervisor_retention">Supervisor Retencion</option>
            <option value="administrator">Administrador del sistema.</option>
          </select>
          <button  class="btn bg-gradient-success mt-4 w-100" @click="create"> Create new user</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {post} from "@/utils/httpRequest";

export default {
  name: "index",
  data: () => ({
    form: {
      name: '',
      email: '',
      percentage: 0,
      password: '',
      type: '',
    }
  }),
  methods: {
    async create(){
      if(this.form.name !== '' && this.form.email !== '' && Number(this.form.percentage) >= 0 && this.form.type !== '' && this.form.password !== ''){
        this.form.percentage = Number((this.form.percentage/100).toFixed(2))
        const {data} = await post('users/create', this.form)
        if(data === 200){
          this.$swal({
            html: 'Process successfully',
            icon: 'success',
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
          this.$router.push('users')
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
@media (max-width: 767px) {
  .margin_top_mobile {
    margin-top: 1.5rem !important;
  }
}
</style>
