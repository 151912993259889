<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item" >
         <div class="text-center">
           <p v-if="user" class="text-uppercase text-dark font-weight-bold mb-0">{{user.name}}</p>
           <p v-if="user" class="text-lowercase text-sm">{{user.email}}</p>
         </div>
      </li>
      <li class="nav-item" v-if="$userCan(['administrator','agent_ftd','agent_retention'])">
        <sidenav-collapse navText="Home" :to="{ name: 'dashboard' }">
          <template #icon>
            <shop />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="$userCan('administrator')">
        <sidenav-collapse navText="Users" :to="{ name: 'users' }">
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Transactions" :to="{ name: 'transactions' }">
          <template #icon>
            <credit-card />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Commissions" :to="{ name: 'commissions' }">
          <template #icon>
            <credit-card />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="$userCan(['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator'])">
        <sidenav-collapse navText="Logs" :to="{ name: 'logs' }">
          <template #icon>
            <credit-card />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="$userCan(['administrator','supervisor_ftd','supervisor_retention'])">
        <sidenav-collapse navText="Leads" :to="{ name: 'leads' }">
          <template #icon>
            <credit-card />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="$userCan(['administrator', 'agent_ftd','supervisor_ftd'])">
        <sidenav-collapse :navText="$userCan(['administrator','supervisor_ftd'])?'Leads FTD':'Leads'" :to="{ name: 'leads-ftd' }">
          <template #icon>
            <credit-card />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="$userCan(['administrator', 'agent_retention','supervisor_retention'])">
        <sidenav-collapse :navText="$userCan(['administrator', 'agent_retention','supervisor_ftd', 'supervisor_retention'])?'Leads Retention':'Leads'" :to="{ name: 'leads-retention' }">
          <template #icon>
            <credit-card />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="$userCan(['administrator','supervisor_ftd','supervisor_retention','agent_retention'])">
        <sidenav-collapse navText="SMS" :to="{ name: 'sms' }">
          <template #icon>
            <credit-card />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="$userCan(['administrator'])">
        <sidenav-collapse navText="Config" :to="{ name: 'config' }">
          <template #icon>
            <credit-card />
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
  <div class="sidenav-footer">
    <a
      class="btn bg-gradient-success mt-4 w-100"
      @click="logout"
      type="button"
      >Close session</a
    >
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import CreditCard from "../../components/Icon/CreditCard.vue";
import {decrypt} from "@/utils/encrypt";
import routerList from "@/router/index"
import {get} from "@/utils/httpRequest";
export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data : () => ({
    title: "Pagos",
    controls: "",
    isActive: "active",
    arrayOptions: [
        {key:'dashboard', name: ''},
      'users','transactions','leads', 'clients'],
    arrayMenu: [],
    user_id: null,
    user: null
  }),
  components: {
    SidenavCollapse,
    Shop,
    Office,
    CreditCard,
  },
  mounted() {
    this.getMenu()
    this.user_id = localStorage.getItem('payment-admin-uuid')
    setTimeout(()=>{
      this.getProfile()
    },500)
  },
  methods: {
    getMenu(){
      const storage = localStorage.getItem('payment-admin-type')
      setTimeout(()=>{
        const rol = decrypt(storage)
        const router = routerList.options.routes;
        this.arrayOptions.forEach(el => {
          router.forEach(item=> {
            if(item.name === el){
              if(item.meta.roles.includes(rol)){
                this.arrayMenu.push(el)
              }
            }
          })
        })
      },500)
    },
    async getProfile(){
      const {data} = await get(`users/find-one?id=${this.user_id}`, null)
      data.password = ''
      this.user = data
    },
    logout() {
      localStorage.clear()
      this.$router.push('/')
    }
  },
};
</script>
