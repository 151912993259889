<template>
  <table class="table table-bordered align-items-center table-sm mt-4">
    <thead>
    <tr>
      <td colspan="4">
        <h6>Accounts List</h6>
      </td>
    </tr>
    <tr>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Bank</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Country</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Account Number</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Swift</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Options</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(item, key) in arrayAccounts" :key="key">
      <td><p class="text-xs font-weight-bold mb-0">{{item.bank}}</p></td>
      <td><p class="text-xs font-weight-bold mb-0">{{item.country}}</p></td>
      <td><p class="text-xs font-weight-bold mb-0">{{item.account_number}}</p></td>
      <td><p class="text-xs font-weight-bold mb-0">{{item.type}}</p></td>
      <td><p class="text-xs font-weight-bold mb-0">{{item.swift}}</p></td>
      <td><p class="text-xs font-weight-bold mb-0">{{item.status}}</p></td>
      <td>
        <p class="text-xs font-weight-bold mb-0">{{$filters.formatDate(item.created_at)}}</p>
      </td>
      <td>
        <div class="row">
          <div class="col">
            <select class="form-control btn-sm" @change="updateStatus(item._id)" v-model="status_selected">
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
              <option value="approved">Approved</option>
            </select>
          </div>
          <div class="col">
            <button class="btn btn-danger w-100 btn-sm" @click="deleteAccount(item._id)">delete</button>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {get, post} from "@/utils/httpRequest";

export default {
  name: "Notes",
  data: () => ({
    user_id: null,
    status_selected: "",
    arrayAccounts: []
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getAccounts()
  },
  methods:{
    async getAccounts(){
      const {data} = await get(`bank-accounts/get-all-by-user?id=${this.user_id}`)
      this.arrayAccounts = data
    },
    async updateStatus(id){
      if(this.status_selected !== ''){
        await post(`bank-accounts/update-status`,{id:id, status: this.status_selected })
        this.status_selected = "";
        this.getAccounts()
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },
    async deleteAccount(id){
      this.$swal({
        html: 'Are you sure?',
        showCancelButton: true,
        confirmButtonText: 'YES',
        cancelButtonText: `NO`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post(`bank-accounts/remove`,{id:id })
          this.status_selected = "";
          await this.getAccounts()
          this.$swal({
            html: 'Process successfully',
            icon: 'success',
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
