<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Transactions list</h6>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <div class="d-flex justify-content-between">
          <div class="w-25">
            <select name="per_page" id="per_page" v-model="pagination.limit" class="form-control" @click="reloadPaginate(1)">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
            </select>
          </div>
        </div>
        <table class="table table-bordered table-sm">
          <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Agent</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Description</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Quantity</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Amount</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date transaction</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Options</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in arrayTransactions" :key="index">
            <td><p class="text-uppercase text-xs font-weight-bold mb-0">{{item.name}} - {{item.email}}</p></td>
            <td><p class="text-uppercase text-xs font-weight-bold mb-0">{{item.agent_name}} - {{item.agent_email}}</p></td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{item.description}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{item.quantity}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{$filters.formatNumber(item.amount)}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{$filters.formatDate(item.created_at)}}</p>
            </td>
            <td>
              <a v-if="item.url_file" :href="item.url_file" target="_blank" class="text-dark font-weight-bold">
                view file
              </a>
              <i  v-if="item.name === undefined || item.amount === 0 || allow_remove" class="fa fa-trash-o text-danger" aria-hidden="true" @click="deleteTransaction(item)" style="cursor: pointer"></i>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-between">
          <div>
            <p class="text-xs font-weight-bold mb-0">total rows: {{pagination.totalRows}}</p>
          </div>
          <div>
            <v-pagination
                v-model="pagination.page"
                :pages="pagination.pages"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="reloadPaginate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get, post} from "@/utils/httpRequest";

export default {
  name: "index",
  data: () => ({
    arrayTransactions: [],
    allow_remove: false,
    pagination: {
      searchTimer: null,
      page: 1,
      pages: 0,
      limit: 20,
      search: '',
      totalRows: 0
    }
  }),
  mounted() {
    this.getTransactions()
   const remove = this.$route.query.remove;
    if(remove && remove === 'true'){
      this.allow_remove = true
    }
  },
  methods: {
    reloadPaginate(page){
      this.pagination.page = page
      this.getTransactions()
    },
    manualSearch(){
      clearTimeout(this.pagination.searchTimer);
      this.pagination.searchTimer = setTimeout(() => {
        this.reloadPaginate(1)
      }, 2000);
    },
    async getTransactions(){
      const {data} = await get(`transactions?page=${this.pagination.page}&limit=${this.pagination.limit}&search=${this.pagination.search}`,null, true)
      this.arrayTransactions = data.docs
      this.pagination.totalRows = data.count
      this.pagination.page = data.page
      this.pagination.pages = data.pages

    },
    async deleteTransaction(item){
      if(item.name === undefined || item.amount === 0){
        this.$swal({
          html: 'Estas seguro?, eliminaras la transaccion y la comision',
          showCancelButton: true,
          confirmButtonText: 'YES',
          cancelButtonText: `NO`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await post('transactions/remove', {_id: item._id} )
            await this.getTransactions()
            this.$swal({
              html: 'Process successfully',
              icon: 'success',
              timer: 2000,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
