<template>
<div class="row mt-4">
  <div class="col">
    <label>GO Card</label>
    <select class="form-control" v-model="configuration.go_card">
      <option value="true">Active</option>
      <option value="false">Inactive</option>
    </select>
    <label>Condonation</label>
    <select class="form-control" v-model="configuration.condonation">
      <option value="true">Active</option>
      <option value="false">Inactive</option>
    </select>
    <label>Fingerprint verification</label>
    <select class="form-control" v-model="configuration.finger">
      <option value="true">Active</option>
      <option value="false">Inactive</option>
    </select>

    <label>Proroga</label>
    <select class="form-control" v-model="configuration.proroga">
      <option value="true">Active</option>
      <option value="false">Inactive</option>
    </select>
    <label>DHL Menu Button</label>
    <select class="form-control" v-model="configuration.shipping">
      <option value="true">Active</option>
      <option value="false">Inactive</option>
    </select>
    <label>Select your network status</label>
    <div class="row">
      <div class="col">
        <select class="form-control" v-model="configuration.network_status">
          <option value="available">Disponible</option>
          <option value="busy">Ocupado</option>
          <option value="disconnected">Desconectado</option>
          <option value="out">Ausente</option>
        </select>
      </div>
      <div class="col">
        <div class="status_wrapper" style="display: flex;align-items: center;">
          <span class="status_circle" :class="status_class"></span>
          <span class="status_text">{{ current_status }}</span>
        </div>
      </div>
    </div>
    <button class="btn btn-success w-100 mt-2" @click="preloadConfiguration">Preload configuration</button>
    <button class="btn btn-danger w-100" @click="createConfiguration">Save all configuration</button>
  </div>
  <div class="col">
    <label>Days <span class="text-danger">Max value 30</span></label>
    <input type="number" min="0" class="form-control" v-model="configuration.time.day">
    <label>Hours <span class="text-danger">Max value 24</span></label>
    <input type="number" min="0" max="23" class="form-control" v-model="configuration.time.hour">
    <label>Minutes <span class="text-danger">Max value 60</span></label>
    <input type="number" min="0" max="59" class="form-control" v-model="configuration.time.minute">
    <button class="btn btn-primary w-100 mt-2" @click="updateTime">Update Time</button>
  </div>
  <div class="col">
    <div class="w-100">
      <h6>Current user configurations:</h6>
      <ul v-if="user_configuration">
        <li>Go Card: <span class="font-weight-bold">{{user_configuration.go_card ? 'ACTIVE':'INACTIVE'}}</span></li>
        <li>Condonation: <span class="font-weight-bold">{{user_configuration.condonation ? 'ACTIVE':'INACTIVE'}}</span></li>
        <li>Fingerprint verification: <span class="font-weight-bold">{{user_configuration.finger ? 'ACTIVE':'INACTIVE'}}</span></li>
        <li>DHL Menu Button: <span class="font-weight-bold">{{user_configuration.shipping ? 'ACTIVE':'INACTIVE'}}</span></li>
        <li>Time: <span class="font-weight-bold">{{user_configuration.time.day}} days. {{user_configuration.time.hour}} minutes. {{user_configuration.time.minute}} second</span></li>
        <li>Network Active: <span class="font-weight-bold">{{user_configuration.network_status}}</span></li>
      </ul>
    </div>
    <hr>
    <div class="w-100">
      <ul>
        <li><span>Save configuration: Guarda la configuracion que este en el formulario</span></li>
        <li> <span>Preload configuration: Carga la configuracion actual del usuario en el formulario</span></li>
        <li> <span>Update Time Only: Actualiza la informacion del contador y el tiempo empieza a calcularse desde el momento que se guarde la informacion</span></li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
import {get, post} from "@/utils/httpRequest";

export default {
  name: "Configurations",
  data: () =>({
    user_id:null,
    current_status: 'out',
    configuration: {
      go_card:false,
      condonation:false,
      finger: false,
      proroga: false,
      shipping: false,
      matrix: false,
      network_status: false,
      time: {
        day: 0,
        hour: 0,
        minute: 0,
      }
    },
    user_configuration: null
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getConfiguration()
  },
  computed: {
    status_class() {
      return `status_${this.current_status}`;
    },
  },
  methods:{
    async createConfiguration(){
      this.$swal({
        html: 'Do you want to save the information?',
        showCancelButton: true,
        confirmButtonText: 'YES',
        cancelButtonText: `NO`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post(`user-configurations/create`,{
            user:this.user_id,
            ...this.configuration
          })
          this.configuration = {
            go_card:false,
            condonation:false,
            finger: false,
            proroga: false,
            shipping: false,
            matrix: false,
            network_status: false,
            time: {
              day: 0,
              hour: 0,
              minute: 0,
            }
          }
          await this.getConfiguration()
          this.$swal({
            html: 'Process successfully',
            icon: 'success',
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      })
    },
    async getConfiguration(){
      const {data} = await get(`user-configurations/by-user?id=${this.user_id}`)
      this.user_configuration = data
      this.configuration.network_status= data.network_status
    },
    async preloadConfiguration(){
      await this.getConfiguration()
      if(this.user_configuration){
        this.configuration = JSON.parse(JSON.stringify(this.user_configuration))
      }
    },
    async updateTime(){
      await post(`user-configurations/update-time`,{
        user:this.user_id,
        time: {
          day: this.configuration.time.day,
          hour: this.configuration.time.hour,
          minute: this.configuration.time.minute,
        }
      })
      this.configuration = {
        go_card:false,
        condonation:false,
        finger: false,
        proroga: false,
        shipping: false,
        matrix: false,
        network_status: false,
        time: {
          day: 0,
          hour: 0,
          minute: 0,
        }
      }
      await this.getConfiguration()
      this.$swal({
        html: 'Process successfully',
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  },
  watch: {
    'configuration.network_status'(new_status) {
      this.current_status = new_status;
    },
  },
}
</script>

<style scoped lang="scss">
.status_circle {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 6px;
}

.status_available {
  background-color: green;
}

.status_busy {
  background-color: red;
}

.status_disconnected {
  background-color: gray;
}

.status_out {
  background-color: orange;
}

.status_text {
  font-size: 14px;
  font-weight: 600;
}
</style>
