<template>
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12">
      <table class="table table-bordered align-items-center table-sm mt-4">
        <thead>
        <tr>
          <td colspan="4">
            <h6>Traced List (Client)</h6>
          </td>
        </tr>
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Description</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Quantity</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Amount</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date Transaction</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Options</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, key) in arrayTransactions" :key="key">
          <td>
            <p class="text-xs font-weight-bold mb-0">{{item.description}}</p>
          </td>
          <td>
            <p class="text-xs font-weight-bold mb-0">{{item.quantity}}</p>
          </td>
          <td>
            <p class="text-xs font-weight-bold mb-0">{{item.amount}}</p>
          </td>
          <td>
            <p class="text-xs font-weight-bold mb-0">{{item.date_transaction}}</p>
          </td>
          <td>
            <p class="text-xs font-weight-bold mb-0">{{$filters.formatDate(item.created_at)}}</p>
          </td>
          <td>
            <button type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_upload_transaction_file" @click="remove(item._id)">delete</button>
          </td>
        </tr>
        <tr>
          <td colspan="2">Total:</td>
          <td>{{totalTransactions}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <label for="">Description</label>
      <input class="form-control" v-model="transaction.description">
      <label for="">Amount</label>
      <input class="form-control" v-model="transaction.amount" type="number">
      <label for="">Date transaction</label>
      <input type="text" class="form-control" v-model="transaction.date_transaction">
      <button class="btn btn-success w-100 mt-4" @click="createTransactions">create new capital</button>
    </div>
  </div>
</template>

<script>
import {get, post} from "@/utils/httpRequest";
export default {
  name: "TracedTransactions",
  data: () => ({
    user_id: null,
    arrayTransactions: [],
    totalTransactions: 0,
    transaction: {
      amount: 0,
      quantity: 1,
      description: '',
      date_transaction: '',
    },
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getTransactions()
  },
  methods:{
    async getTransactions(){
      const {data} = await get(`traced-capital/by-user?id=${this.user_id}`)
      this.arrayTransactions = data
      this.totalTransactions = data.map(x => x.amount).reduce((prev,next)=> prev+next ,0)
    },
    async createTransactions(){
      if(this.transaction.description !== '' && this.transaction.amount >= 0){
        await post("traced-capital/create", {
          ...this.transaction,
          user: this.user_id
        })
        this.transaction = {
          amount: 0,
          quantity: 1,
          description: ''
        }
        await this.getTransactions()
      }
    },
    async remove(id){
      await post("traced-capital/remove", {id: id})
      await this.getTransactions()
      window.location.reload()
    }
  }
}
</script>

<style scoped>

</style>
