<template>
  <table class="table table-bordered align-items-center table-sm mt-4">
    <thead>
    <tr>
      <td colspan="4">
        <h6>Documents List</h6>
      </td>
    </tr>
    <tr>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Url</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Options</th>
    </tr>
    </thead>
    <tbody v-if="documents">
    <!--    ========================== Address -->
    <tr>
      <td>Address</td>
      <td>
        <a :href="documents.address" target="_blank" v-if="documents.address">URL</a>
        <span v-else class="text-danger">No uploaded</span>
      </td>
      <td>
        {{documents.address_status}}
      </td>
      <td>
        <div class="row">
          <div class="col">
            <select class="form-control btn-sm" @change="updateStatus('address')" v-model="status_selected">
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
              <option value="approved">Approved</option>
            </select>
          </div>
          <div class="col">
            <button class="btn btn-danger w-100 btn-sm" @click="deleteAccount('address')">delete</button>
          </div>
        </div>
      </td>
    </tr>
    <!--    ========================== BANK-->
    <tr>
      <td>Bank</td>
      <td>
        <a :href="documents.bank" target="_blank" v-if="documents.bank">URL</a>
        <span v-else class="text-danger">No uploaded</span>
      </td>
      <td>
        {{documents.bank_status}}
      </td>
      <td>
        <div class="row">
          <div class="col">
            <select class="form-control btn-sm" @change="updateStatus('bank')" v-model="status_selected">
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
              <option value="approved">Approved</option>
            </select>
          </div>
          <div class="col">
            <button class="btn btn-danger w-100 btn-sm" @click="deleteAccount('bank')">delete</button>
          </div>
        </div>
      </td>
    </tr>
    <!--    ========================== Certificate-->
    <tr>
      <td>Certificate</td>
      <td>
        <a :href="documents.certificate" target="_blank" v-if="documents.certificate">URL</a>
        <span v-else class="text-danger">No uploaded</span>
      </td>
      <td>
        {{documents.certificate_status}}
      </td>
      <td>
        <div class="row">
          <div class="col">
            <select class="form-control btn-sm" @change="updateStatus('certificate')" v-model="status_selected">
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
              <option value="approved">Approved</option>
            </select>
          </div>
          <div class="col">
            <button class="btn btn-danger w-100 btn-sm" @click="deleteAccount('certificate')">delete</button>
          </div>
        </div>
      </td>
    </tr>
    <!--    ========================== Condonation-->
    <tr>
      <td>
        Condonation
      </td>
      <td>
        <a :href="documents.condonation" target="_blank" v-if="documents.condonation">URL</a>
        <span v-else class="text-danger">No uploaded</span>
      </td>
      <td>
        {{documents.condonation_status}}
      </td>
      <td>
        <div class="row">
          <div class="col">
            <select class="form-control btn-sm" @change="updateStatus('condonation')" v-model="status_selected">
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
              <option value="approved">Approved</option>
            </select>
          </div>
          <div class="col">
            <button class="btn btn-danger w-100 btn-sm" @click="deleteAccount('condonation')">delete</button>
          </div>
        </div>
      </td>
    </tr>
    <!--    ========================== Finger-->
    <tr>
      <td>
        Finger
      </td>
      <td>
        <a :href="documents.finger" target="_blank" v-if="documents.finger">URL</a>
        <span v-else class="text-danger">No uploaded</span>
      </td>
      <td>
        {{documents.finger_status}}
      </td>
      <td>
        <div class="row">
          <div class="col">
            <select class="form-control btn-sm" @change="updateStatus('finger')" v-model="status_selected">
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
              <option value="approved">Approved</option>
            </select>
          </div>
          <div class="col">
            <button class="btn btn-danger w-100 btn-sm" @click="deleteAccount('finger')">delete</button>
          </div>
        </div>
      </td>
    </tr>
    <!--    ========================== ID BACK -->
    <tr>
      <td>
        ID Back
      </td>
      <td>
        <a :href="documents.id_back" target="_blank" v-if="documents.id_back">URL</a>
        <span v-else class="text-danger">No uploaded</span>
      </td>
      <td>
        {{documents.id_back_status}}
      </td>
      <td>
        <div class="row">
          <div class="col">
            <select class="form-control btn-sm" @change="updateStatus('id_back')" v-model="status_selected">
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
              <option value="approved">Approved</option>
            </select>
          </div>
          <div class="col">
            <button class="btn btn-danger w-100 btn-sm" @click="deleteAccount('id_back')">delete</button>
          </div>
        </div>
      </td>
    </tr>
    <!--    ========================== ID FRONT -->
    <tr>
      <td>
        ID Front
      </td>
      <td>
        <a :href="documents.id_front" target="_blank" v-if="documents.id_front">URL</a>
        <span v-else class="text-danger">No uploaded</span>
      </td>
      <td>
        {{documents.id_front_status}}
      </td>
      <td>
        <div class="row">
          <div class="col">
            <select class="form-control btn-sm" @change="updateStatus('id_front')" v-model="status_selected">
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
              <option value="approved">Approved</option>
            </select>
          </div>
          <div class="col">
            <button class="btn btn-danger w-100 btn-sm" @click="deleteAccount('id_front')">delete</button>
          </div>
        </div>
      </td>
    </tr>
    <!--    ========================== Selfie -->
    <tr>
      <td>
        Selfie
      </td>
      <td>
        <a :href="documents.selfie" target="_blank" v-if="documents.selfie">URL</a>
        <span v-else class="text-danger">No uploaded</span>
      </td>
      <td>
        {{documents.selfie_status}}
      </td>
      <td>
        <div class="row">
          <div class="col">
            <select class="form-control btn-sm" @change="updateStatus('selfie')" v-model="status_selected">
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
              <option value="approved">Approved</option>
            </select>
          </div>
          <div class="col">
            <button class="btn btn-danger w-100 btn-sm" @click="deleteAccount('selfie')">delete</button>
          </div>
        </div>
      </td>
    </tr>
    <!--    ========================== Proroga -->
    <tr>
      <td>
        Proroga
      </td>
      <td>
        <a :href="documents.proroga" target="_blank" v-if="documents.proroga">URL</a>
        <span v-else class="text-danger">No uploaded</span>
      </td>
      <td>
        {{documents.proroga_status}}
      </td>
      <td>
        <div class="row">
          <div class="col">
            <select class="form-control btn-sm" @change="updateStatus('proroga')" v-model="status_selected">
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
              <option value="approved">Approved</option>
            </select>
          </div>
          <div class="col">
            <button class="btn btn-danger w-100 btn-sm" @click="deleteAccount('proroga')">delete</button>
          </div>
        </div>
      </td>
    </tr>




    </tbody>
  </table>
</template>

<script>
import {get, post} from "@/utils/httpRequest";

export default {
  name: "Notes",
  data: () => ({
    user_id: null,
    documents: null,
    status_selected: '',
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getDocuments()
  },
  methods:{
    async getDocuments(){
      const {data} = await get(`documents/get-documents-by-user?id=${this.user_id}`)
      this.documents = data
    },
    async updateStatus(location){
      await post('documents/update-status',{id: this.user_id, status: this.status_selected, location: location})
      this.status_selected = "";
      await this.getDocuments()
      this.$swal({
        html: 'Process successfully',
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
    async deleteAccount(location){
      this.$swal({
        html: 'Are you sure?',
        showCancelButton: true,
        confirmButtonText: 'YES',
        cancelButtonText: `NO`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post('documents/remove',{id: this.user_id, location: location})
          await this.getDocuments()
          this.$swal({
            html: 'Process successfully',
            icon: 'success',
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
