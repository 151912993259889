<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Commissions list</h6>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <div class="d-flex justify-content-between">
          <div class="w-25">
            <select name="per_page" id="per_page" v-model="pagination.limit" class="form-control" @click="reloadPaginate(1)">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
            </select>
          </div>
        </div>
        <table class="table table-bordered table-sm">
          <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Agent</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Description</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Amount</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date transaction</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in arrayTransactions" :key="index">
            <td><p class="text-uppercase text-xs font-weight-bold mb-0">{{item.name}} - {{item.email}}</p></td>
            <td><p class="text-uppercase text-xs font-weight-bold mb-0">{{item.agent_name}} - {{item.agent_email}}</p></td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{item.description}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{$filters.formatNumber(item.amount)}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{$filters.formatDate(item.created_at)}}</p>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-between">
          <div>
            <p class="text-xs font-weight-bold mb-0">total rows: {{pagination.totalRows}}</p>
          </div>
          <div>
            <v-pagination
                v-model="pagination.page"
                :pages="pagination.pages"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="reloadPaginate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get} from "@/utils/httpRequest";

export default {
  name: "index",
  data: () => ({
    arrayTransactions: [],
    pagination: {
      searchTimer: null,
      page: 1,
      pages: 0,
      limit: 20,
      search: '',
      totalRows: 0
    }
  }),
  mounted() {
    this.getTransactions()
  },
  methods: {
    reloadPaginate(page){
      this.pagination.page = page
      this.getTransactions()
    },
    manualSearch(){
      clearTimeout(this.pagination.searchTimer);
      this.pagination.searchTimer = setTimeout(() => {
        this.reloadPaginate(1)
      }, 2000);
    },
    async getTransactions(){
      const {data} = await get(`commissions?page=${this.pagination.page}&limit=${this.pagination.limit}&search=${this.pagination.search}`,null, true)
      this.arrayTransactions = data.docs
      this.pagination.totalRows = data.count
      this.pagination.page = data.page
      this.pagination.pages = data.pages

    }
  }
}
</script>

<style scoped>

</style>
