<template>
  <div class="w-100">

    <div class="card mb-4">
      <div class="card-header pb-0">
        <h6 class="text-uppercase" v-if="user">{{user.type}} details</h6>
      </div>
      <div class="card-body px-4">
        <div class="row">
          <div class="col">
            <ul style="list-style: none" v-if="user">
              <li><span class="font-weight-bold">Name:</span>
                <input type="text" class="form-control" v-model="user.name">
              </li>
              <li><span class="font-weight-bold">Email:</span>
                <input type="text" class="form-control" v-model="user.email">
              </li>
              <li><span class="font-weight-bold">Identification:</span>
                <input type="text" class="form-control" v-model="user.identification">
              </li>
              <li><span class="font-weight-bold">Number phone:</span>
                <input type="text" class="form-control" v-model="user.phone">
              </li>
              <li><span class="font-weight-bold">Status:</span>
                <select class="form-control" name="user_status" id="usr_status" v-model="user.status">
                  <option value="true">ACTIVO</option>
                  <option value="false">INACTIVO</option>
                </select>
              </li>
              <li><span class="font-weight-bold">Country:</span>
                <input type="text" class="form-control" v-model="user.country">
              </li>
              <li><span class="font-weight-bold">Campaign:</span>
                <input type="text" class="form-control" v-model="user.campaign">
              </li>
              <li><span class="font-weight-bold">Password:</span>
                <input type="text" class="form-control" v-model="user.password_text">
              </li>
              <li class="mt-2"><span class="font-weight-bold">Date registered:</span><span class="form-control">{{$filters.formatDate(user.created_at)}}</span></li>
              <li><span class="font-weight-bold">Agent FTD: </span>
                <span v-if="agentAssigned.ftd" type="text" class="form-control">{{agentAssigned.ftd.name}} - {{agentAssigned.ftd.email}}</span>
                <span v-else type="text" class="">Does not has agent assigned</span>
              </li>
              <li><span class="font-weight-bold">Agent Retention: </span>
                <span v-if="agentAssigned.retention" type="text" class="form-control">{{agentAssigned.retention.name}} - {{agentAssigned.retention.email}}</span>
                <span v-else type="text" class="form-control text-danger"> Does not has agent assigned</span>
              </li>
            </ul>
            <div class="w-100" v-if="$userCan(['administrator','supervisor_ftd','supervisor_retention', 'agent_retention'])">
              <button class="btn btn-success w-100" @click="updateUser">update information user</button>
            </div>
          </div>
          <div class="col">
            <div class="w-100" v-if="user">
              <label class="text-danger">User token (use this token for shipping platform)</label>
              <div class="input-group mb-3">
                <p type="text" class="form-control border-danger fw-bold" style="font-size: 1.5rem">{{user._id}}</p>
                <div class="input-group-append">
                  <button class="btn btn-outline-danger" type="button" @click="copyToClipboard">Copy</button>
                </div>
              </div>
            </div>
              <div v-if="$userCan(['administrator','supervisor_ftd','supervisor_retention'])">
                <select name="combo_agent" id="combo_agent" class="form-control" v-model="agent_ftd_selected" >
                  <option value="">Choice agent FTD</option>
                  <option :value="item._id" v-for="(item, index) in arrayAgentsFtd" :key="index">{{item.name}} {{item.email}}</option>
                </select>
                <div class="w-100 mt-2">
                  <button class="btn btn-success w-100" @click="assignAgentFtd">Assign agent FTD</button>
                </div>
                <hr>
              </div>
              <div v-if="$userCan(['administrator','supervisor_ftd','supervisor_retention', 'agent_retention'])">
                <select name="combo_agent" id="combo_agent" class="form-control" v-model="agent_retention_selected">
                  <option value="">Choice agent retention</option>
                  <option :value="item._id" v-for="(item, index) in arrayAgentsRetention" :key="index">{{item.name}} {{item.email}}</option>
                </select>
                <div class="w-100 mt-2">
                  <button class="btn btn-success w-100" @click="assignAgentRetention">Assign agent retention</button>
                </div>
              </div>
              <div class="w-100 mt-2" v-if="user && user.type === 'leads'">
                <button class="btn btn-danger w-100" @click="updateRolToClient">Update rol to client</button>
              </div>
          </div>
          <div class="col">
            <div class="w-100">
              <h6>Internal notes to client</h6>
            </div>
            <div class="w-100">
              <textarea v-model="note" rows="6" cols="50" class="form-control"></textarea>
            </div>
            <div class="w-100 mt-2">
              <button class="btn btn-success w-100" @click="createNote">Create note</button>
            </div>
            <hr>

        <div v-if="$userCan(['administrator','supervisor_ftd','agent_retention'])">
          <h6>Internal transactions (Sales)</h6>
          <div class="w-100">
            <label>Enter description</label>
          </div>
          <div class="w-100">
            <input class="form-control w-100" v-model="transaction.description" type="text">
          </div>
<!--          <div class="w-100">-->
<!--            <label>Enter quantity</label>-->
<!--          </div>-->
<!--          <div class="w-100">-->
<!--            <input class="form-control w-100" v-model="transaction.quantity" type="number">-->
<!--          </div>-->
          <div class="w-100">
            <label>Enter total amount <span class="text-danger"> values can be +/-</span></label>
          </div>
          <div class="w-100">
            <input class="form-control w-100" v-model="transaction.amount" type="number">
          </div>
          <div class="w-100 mt-2">
            <span class="text-danger">Will create a internal transaction and give commission to agents assigned</span>
            <button class="btn btn-success w-100" @click="createTransactions">create new internal transaction</button>
          </div>
        </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 pb-4">
    <div class="card mb-4">
      <div class="card-header pb-0">
        <h6>Configurations</h6>
      </div>
      <div class="card-body px-4">
        <ul class="nav nav-tabs nav-fill">
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active" data-bs-toggle="tab" href="#notes-tab" role="tab" aria-controls="notes" aria-selected="true">Notes</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#transactions-tab" role="tab" aria-controls="transactions" aria-selected="false">
              Sales (Internal)
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#traced-tab" role="tab" aria-controls="traced" aria-selected="false">
              Traced (Client)
            </a>
          </li>
          <li class="nav-item" v-if="$userCan(['administrator','supervisor_ftd','supervisor_retention','agent_retention'])">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#notifications-tab" role="tab" aria-controls="notifications" aria-selected="false">
              Notifications
            </a>
          </li>
          <li class="nav-item" v-if="$userCan(['administrator','supervisor_ftd','supervisor_retention','agent_retention'])">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#transfer-tab" role="tab" aria-controls="transfer" aria-selected="false">
              Transfer Notification
            </a>
          </li>
          <li class="nav-item" v-if="$userCan(['administrator','supervisor_ftd','supervisor_retention','agent_retention'])">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#configuration-tab" role="tab" aria-controls="configuration" aria-selected="false">
              Configuration
            </a>
          </li>
          <li class="nav-item" v-if="$userCan(['administrator','supervisor_ftd','supervisor_retention','agent_retention'])">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#accounts-tab" role="tab" aria-controls="accounts" aria-selected="false">
              Accounts
            </a>
          </li>
          <li class="nav-item" v-if="$userCan(['administrator','supervisor_ftd','supervisor_retention','agent_retention'])">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#documents-tab" role="tab" aria-controls="documents" aria-selected="false">
              Documents
            </a>
          </li>
          <li class="nav-item" v-if="$userCan(['administrator','supervisor_ftd','supervisor_retention','agent_retention'])">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#card-tab" role="tab" aria-controls="card" aria-selected="false">
              Go card
            </a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="notes-tab" role="tabpanel" aria-labelledby="notes-tab">
            <Notes ref="user_detail_notes"/>
          </div>
          <div class="tab-pane fade" id="transactions-tab" role="tabpanel" aria-labelledby="transactions-tab">
          <Transactions ref="user_detail_transactions"/>
          </div>
          <div class="tab-pane fade" id="traced-tab" role="tabpanel" aria-labelledby="traced-tab">
          <TracedTransactions/>
          </div>
          <div class="tab-pane fade" id="notifications-tab" role="tabpanel" aria-labelledby="notifications-tab">
            <Notifications/>
          </div>
          <div class="tab-pane fade" id="transfer-tab" role="tabpanel" aria-labelledby="transfer-tab">
            <Transfers/>
          </div>
          <div class="tab-pane fade" id="configuration-tab" role="tabpanel" aria-labelledby="configuration-tab">
            <Configurations/>
          </div>
          <div class="tab-pane fade" id="accounts-tab" role="tabpanel" aria-labelledby="accounts-tab">
           <Accounts/>
          </div>
          <div class="tab-pane fade" id="documents-tab" role="tabpanel" aria-labelledby="documents-tab">
            <Documents/>
          </div>
          <div class="tab-pane fade" id="card-tab" role="tabpanel" aria-labelledby="card-tab">
            <GoCard/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notifications from "@/views/Users/Notifications.vue";
import Transfers from "@/views/Users/Transfers.vue";
import Configurations from "@/views/Users/Configurations.vue";
import Transactions from "@/views/Users/Transactions.vue";
import Notes from "@/views/Users/Notes.vue";
import Accounts from "@/views/Users/Accounts.vue";
import Documents from "@/views/Users/Documents.vue";
import GoCard from "@/views/Users/GoCard.vue";
import TracedTransactions from "@/views/Users/TracedTransactions.vue";
import {get, post} from "@/utils/httpRequest";
export default {
  name: "index",
  components:{
    Configurations,
    Notifications,
    Transfers,
    Transactions,
    Notes,
    Accounts,
    Documents,
    GoCard,
    TracedTransactions
  },
  data: () => ({
    arrayUsers: [],
    arrayAgentsFtd: [],
    arrayAgentsRetention: [],
    agent_ftd_selected: '',
    agent_retention_selected: '',
    user_id: null,
    user: null,
    agentAssigned: null,
    note: '',
    url_file: '',
    transaction: {
      amount: 0,
      quantity: 1,
      description: ''
    },

    tabSelected: 'notes',
    totalTransactions: 0
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getProfile()
    this.getAgentsFtd()
    this.getAgentsRetention()
  },
  methods: {
    async getProfile(){
      const {data} = await get(`users/find-one?id=${this.user_id}`, null)
      data.password = ''
      this.user = data
      this.agentAssigned = {
        ftd: data.agent_ftd,
        retention: data.agent_retention
      }
    },
    async createNote(){
      if(this.note !== ''){
        await post("notes/create",{description: this.note, user: this.user_id})
        this.note = '';
        this.$refs.user_detail_notes.getNotes()
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },

    async createTransactions(){
      if(this.transaction.description !== '' && this.transaction.amount !== '' && this.transaction.quantity > 0 && this.transaction.amount !== 0){
        await post("transactions/create",{
          ...this.transaction,
          user: this.user_id
        })
        this.$refs.user_detail_transactions.getTransactions()
        this.transaction.amount = 0
        this.transaction.description = ''
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },

    async getAgentsFtd(){
      const {data} = await get('users/all-agents-ftd')
      this.arrayAgentsFtd = data
    },
    async getAgentsRetention(){
      const {data} = await get('users/all-agents-retention')
      this.arrayAgentsRetention = data
    },
    async assignAgentRetention(){
      if(this.agent_retention_selected !== ''){
        await post('users/assign-agent-by-user-id',{type: 'retention', _id: this.user_id, agent: this.agent_retention_selected})
        await this.getProfile()
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },
    async assignAgentFtd(){
      if(this.agent_ftd_selected !== ''){
        await post('users/assign-agent-by-user-id',{type: 'ftd', _id: this.user_id, agent: this.agent_ftd_selected})
        await this.getProfile()
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },
    async updateUser() {
      await post('users/update', {...this.user, password: this.user.password_text, _id: this.user_id})
      await this.getProfile()
      this.$swal({
        html: 'Process successfully',
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
    async updateRolToClient(){
      this.$swal({
        html: 'Do you want to update the role? New Role: Client',
        showDenyButton: true,
        confirmButtonText: 'YES',
        cancelButtonText: `NO`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post("users/update-rol-client",{_id: this.user_id})
          await this.getProfile()
          this.$swal({
            html: 'Process successfully',
            icon: 'success',
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      })
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.user_id).then(() => {
        this.$swal({
          html: 'Copy successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      });
    }
  }
}
</script>

<style scoped>

</style>
