<template>
<div class="row mt-4">
  <div class="col">
    <table class="table table-bordered align-items-center table-sm">
      <thead>
      <tr>
        <td colspan="4">
          <h6>Notifications List</h6>
        </td>
      </tr>
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Description</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Options</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, key) in arrayNotifications" :key="key">
        <td>
          <p class="text-xs font-weight-bold mb-0">{{item.title}}</p>
        </td>
        <td>
          <p class="text-xs font-weight-bold mb-0">{{item.description}}</p>
        </td>
        <td>
          <p class="text-xs font-weight-bold mb-0">{{$filters.formatDate(item.created_at)}}</p>
        </td>
        <td>
          <button class="btn bg-gradient-danger btn-sm" @click="removeNotification(item._id)">delete</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="col">
    <label>Title</label>
    <input type="text" class="form-control" v-model="notification.title">
    <label>Description</label>
    <input type="text" class="form-control" v-model="notification.description">
    <button  class="btn bg-gradient-success mt-4 w-100" @click="createNotifications"> Create notification</button>
  </div>
</div>
</template>

<script>
import {get, post} from "@/utils/httpRequest";

export default {
  name: "Notifications",
  data: () => ({
    user_id: null,
    arrayNotifications: [],
    notification: {
      title: '',
      description: ''
    }
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getNotifications()
  },
  methods:{
    async getNotifications(){
        const {data} = await get(`notifications/get-by-user?id=${this.user_id}`)
      this.arrayNotifications = data
    },
    async createNotifications(){
      if(this.notification.title !== '' && this.notification.description !== ''){
        await post("notifications/create",{...this.notification, user: this.user_id})
        this.notification.title = ''
        this.notification.description = ''
        await this.getNotifications()
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },
    async removeNotification(_id){
      await post("notifications/remove",{_id: _id})
      await this.getNotifications()
      this.$swal({
        html: 'Process successfully',
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
}
</script>

<style scoped>

</style>
