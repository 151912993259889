<template>
  <div class="card">
    <div class="card-header">
      <h6>Create Leads</h6>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col">
          <label>Names</label>
          <input type="text" class="form-control" v-model="form.name">
          <label>Email</label>
          <input type="text" class="form-control" v-model="form.email">
          <label>Identification</label>
          <input type="text" class="form-control" v-model="form.identification">
          <label>Phone</label>
          <input type="text" class="form-control" v-model="form.phone">
          <label>Country</label>
          <input type="text" class="form-control" v-model="form.country">
          <label>Campaign</label>
          <input type="text" class="form-control" v-model="form.campaign">
          <label>Password</label>
          <input type="text" class="form-control" v-model="form.password">
          <button  class="btn bg-gradient-success w-100 mt-4" @click="create"> Create new lead</button>
        </div>
        <div class="col">
          <h6>Import user from another platform</h6>
          <input type="text" class="form-control" placeholder="Enter user id" v-model="user_id_import">
          <table class="table table-bordered table-sm mt-2">
           <tbody>
           <tr>
             <td>Name and Email </td>
             <td v-if="user_info_import.user">{{user_info_import.user.name}}</td>
             <td v-if="user_info_import.user">{{user_info_import.user.email}}</td>
           </tr>
           <tr>
             <td>Country and identification </td>
             <td v-if="user_info_import.user">{{user_info_import.user.country}}</td>
             <td v-if="user_info_import.user">{{user_info_import.user.identification}}</td>
           </tr>
           <tr>
             <td>Transacctions: </td>
             <td v-if="user_info_import.transaction">{{user_info_import.transaction.total_transactions}}</td>
             <td v-if="user_info_import.transaction">{{user_info_import.transaction.total_amount}}</td>
           </tr>
           </tbody>
          </table>
          <button  class="btn btn-primary w-100 mt-2" @click="getInformationFromAnotherPlatform"> Get user information from another platform</button>
          <button  class="btn btn-primary w-100" @click="importUserFromAnotherPlatform"> Import user from another platform</button>
          <hr class="mt-2 mb-4">
          <h6>Import user from Excel</h6>
          <label class="mt-4">Select the file you want to import <br><span class="text-danger">This process may take a few minutes depending on the amount of information.</span></label>
          <form class="d-flex justify-content-around" enctype="multipart/form-data" @submit.prevent="importLeads">
            <div class="w-100">
              <input id="myFile" ref="file" class="" type="file" @change="onSelect($event)">
              <a href="https://recoverydocs.s3.us-west-1.amazonaws.com/FORMATO%2BIMPORTAR%2BLEADS.xlsx" target="_blank" class="btn btn-danger w-100 mt-4"> Download excel</a>
              <button type="submit" class="btn btn-danger w-100">Import excel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post} from "@/utils/httpRequest";
import uploadFile from "@/utils/uploadFile";
import axios from 'axios';
export default {
  name: "index",
  data: () => ({
    file: '',
    form: {
      name: '',
      email: '',
      phone: '',
      identification: '',
      password: '',
      type: 'leads',
      country: '',
      campaign: ''
    },
    user_id_import: '',
    user_info_import: {
     user: null,
     transactions: [],
      transaction: null
    }
  }),
  methods: {
    async create(){
      if(this.form.name !== '' && this.form.email !== '' && this.form.phone !== '' && this.form.identification !== '' && this.form.password !== '' && this.form.campaign !== '' && this.form.country !== ''){
        const {data} = await post('users/create-leads', this.form, true)
        if(data === 200){
          this.$swal({
            html: 'Process successfully',
            icon: 'success',
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
          this.form = {
            name: '',
            email: '',
            phone: '',
            identification: '',
            password: '',
            type: 'agent',
          }
        }
      }
    },
    async importLeads(){
        if (this.file !== undefined && this.file !== '') {
          await uploadFile.upload('users/upload-excel',{file: this.file})
          this.$swal({
            html: 'Process successfully. <br> This process may take a few minutes depending on the amount of information.',
            icon: 'success',
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
          window.location.reload()
        }
    },
    resetInputFile(e) {
      e.target.value = '';
    },
    onSelect(e) {
      this.file = e.target.files[0];
    },
    async getInformationFromAnotherPlatform(){
      if(this.user_id_import.length > 10){
        const { data } = await axios.get(process.env.VUE_APP_PLATFORM_IMPORT+`auth/export-information-by-user-id?id=${this.user_id_import}`)
        this.user_info_import = data
      }
    },
    async importUserFromAnotherPlatform(){
      if(this.user_id_import.user !== null && this.user_id_import.user !== ''){
        await post(`users/import-user-from-another-platform`, this.user_info_import)
        this.user_info_import = {
          user: null,
          transactions: [],
          transaction: null
        }
        this.user_id_import = '';
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
