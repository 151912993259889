<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
            <div class="row">
              <div class="col-lg-4 col-sm-12">
                <h6>Leads</h6>
              </div>
              <div class="col-lg-6 col-sm-12" v-if="$userCan('administrator')">
                <div class="row">
                  <div class="col-lg-3 col-sm-12">
                    <input type="number" placeholder="Ingrese cantidad" class="form-control" v-model="users_selected">
                  </div>
                  <div class="col">
                    <select name="combo_agent" id="combo_agent" class="form-control" v-model="agent_selected">
                      <option value="">Choice agent</option>
                      <option :value="item._id" v-for="(item, index) in arrayAgents" :key="index">{{item.name}} {{item.email}}</option>
                    </select>
                  </div>
                  <div class="col-lg-3 col-sm-12 margin_top_mobile">
                    <button  class="btn btn-danger btn-sm w-100" @click="assignAgent"> Assign leads</button>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-sm-12" v-if="$userCan('administrator')">
                <button  class="btn btn-success btn-sm w-100" @click="$router.push('/users-create-lead')"> Create new leads</button>
              </div>
            </div>
    </div>
    <div class="card-body px-4">
      <div class="table-responsive">
        <div class="d-flex justify-content-between">
          <div class="w-25">
            <select name="per_page" id="per_page" v-model="pagination.limit" class="form-control" @click="reloadPaginate(1)">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
            </select>
          </div>
          <div class="w-25">
            <input type="text" class="form-control" placeholder="Type to search by email or name" v-model="pagination.search" @keyup="manualSearch">
          </div>
        </div>
        <table class="table table-bordered align-items-center table-sm">
          <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Email</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Country</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Campaign</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Options</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, key) in arrayUsers" :key="key">
            <td>
              <p class="text-uppercase text-xs font-weight-bold mb-0">{{item.name}}</p>
            </td>
            <td>
              <p class="text-uppercase text-xs font-weight-bold mb-0">{{item.email}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{item.country}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{item.campaign}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{item.status?'ACTIVE':'INACTIVE'}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{$filters.formatDate(item.created_at)}}</p>
            </td>
            <td class="">
              <button class="btn btn-success btn-sm" @click="vieDetail(item)">View</button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-between">
          <div>
            <p class="text-xs font-weight-bold mb-0">total rows: {{pagination.totalRows}}</p>
          </div>
          <div>
            <v-pagination
                v-model="pagination.page"
                :pages="pagination.pages"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="reloadPaginate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get, post} from "@/utils/httpRequest";
export default {
  name: "index",
  data: () => ({
    arrayUsers: [],
    arrayAgents: [],
    users_selected: 0,
    agent_selected: '',
    pagination: {
      searchTimer: null,
      page: 1,
      pages: 0,
      limit: 20,
      search: '',
      totalRows: 0
    }
  }),
  mounted() {
    this.getData()
    this.getAgents()
  },

  methods: {

    reloadPaginate(page){
      this.pagination.page = page
      this.getData()
    },
    manualSearch(){
      clearTimeout(this.pagination.searchTimer);
      this.pagination.searchTimer = setTimeout(() => {
        this.reloadPaginate(1)
      }, 2000);
    },

    async getData(){
      const {data} = await get(`users/find-all-leads?page=${this.pagination.page}&limit=${this.pagination.limit}&search=${this.pagination.search}&agent=false` )
      this.arrayUsers = data.docs
      this.pagination.totalRows = data.count
      this.pagination.page = data.page
      this.pagination.pages = data.pages
    },
    vieDetail(item){
      this.$router.push({name:'users-view', query: {id: item._id}})
    },
    async assignAgent(){
      if(this.users_selected>0 && this.agent_selected !== ''){
        await post("users/assign-agent",{quantity: this.users_selected, agent: this.agent_selected})
        this.users_selected = 0
        this.agent_selected = ''
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
        await this.reloadPaginate(1)
      }
    },
    async getAgents(){
      const {data} = await get('users/all-agents-ftd')
      this.arrayAgents = data
    }
  }
}
</script>
<style scoped lang="scss">
@media (max-width: 767px) {
  .margin_top_mobile {
    margin-top: 1.5rem !important;
  }
}
</style>
