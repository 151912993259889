<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>Configuration</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col">
          <label>Sipping url. Example: https://miempresa.com</label>
          <input class="form-control" placeholder="Enter shipping url website" v-model="config.url_shipping">
          <label>Matrix url</label>
          <input class="form-control mt-1" placeholder="Enter matrix url website" v-model="config.url_matrix">
        </div>
        <div class="col">
          <button class="btn btn-success w-100 mt-1" @click="saveConfig">Save configurarion</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get, post} from "@/utils/httpRequest";

export default {
  name: "Config",
  data: () => ({
    config: {
      _id: "",
      url_shipping: "",
      url_matrix: "",
    },
  }),
  mounted() {
    this.getConfig();
  },
  methods: {
    async saveConfig() {
      await post("general-config/create", this.config);
      await this.getConfig()
      this.$swal({
        html: 'Process successfully',
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },

    async getConfig() {
      const { data } = await get("general-config");
      if (data) {
        this.config = data
      }
    }
  }
}
</script>

<style scoped>

</style>
