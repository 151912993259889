<template>
  <div class="mt-4">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col">
            <input type="number" class="form-control" v-model="balance">
          </div>
          <div class="col">
            <button class="btn btn-success" @click="addBalance">Add balance Go Card</button>
          </div>
        </div>
      </div>
      <div class="col">
        <select class="form-control" @change="updateStatus" v-model="status_selected">
          <option value="">Choice an option</option>
          <option value="true">Approved</option>
          <option value="false">Not Aproved</option>
        </select>
      </div>
      <div class="col text-end">
        <button class="btn btn-danger" @click="remove">Delete Go Card</button>
      </div>
    </div>
    <table class="table table-bordered align-items-center table-sm mt-4">
      <thead>
      <tr>
        <td colspan="4">
          <h6>Go Card</h6>
        </td>
      </tr>
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Field</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Value</th>
      </tr>
      </thead>
      <tbody v-if="goCard">
      <tr>
        <td>name</td>
        <td>{{goCard.name}}</td>
      </tr>
      <tr>
        <td>address</td>
        <td>{{goCard.address}}</td>
      </tr>
      <tr>
        <td>balance</td>
        <td>{{goCard.balance}}</td>
      </tr>
      <tr>
        <td>city</td>
        <td>{{goCard.city}}</td>
      </tr>
      <tr>
        <td>country</td>
        <td>{{goCard.country}}</td>
      </tr>
      <tr>
        <td>identification</td>
        <td>{{goCard.identification}}</td>
      </tr>
      <tr>
        <td>shipping</td>
        <td>{{goCard.shipping}}</td>
      </tr>
      <tr>
        <td>Status</td>
        <td>{{!goCard.status ? 'No approved': 'Approved' }}</td>
      </tr>
      <tr>
        <td>created_at</td>
        <td>{{goCard.created_at}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {get, post} from "@/utils/httpRequest";

export default {
  name: "Notes",
  data: () => ({
    user_id: null,
    goCard: null,
    balance: 0,
    status_selected: ''
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getGoCard()
  },
  methods:{
    async getGoCard(){
      const {data} = await get(`go-card/by-user?id=${this.user_id}`)
      this.goCard = data
    },

    async addBalance(){
      if(this.balance !== 0){
        await post('go-card/add-balance',{id: this.user_id, balance: this.balance})
        await this.getGoCard()
        this.balance = 0
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },
    async remove(){
      await post('go-card/remove',{id: this.user_id})
      await this.getGoCard()
      this.$swal({
        html: 'Process successfully',
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
    async updateStatus(){
      if(this.status_selected !== ''){
        await post('go-card/update-status',{id: this.user_id, status: this.status_selected})
        await this.getGoCard()
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
