<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>SMS Logs</h6>
        </div>
        <div class="col text-end">
          <h6>Balance: {{ balance }}</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <ul class="nav nav-pills nav-fill" id="myTabs">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" data-bs-toggle="tab" href="#tab1" @click="selectTabs('tab1')">SMS LIST</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" data-bs-toggle="tab" href="#tab2" @click="selectTabs('tab2')">SMS TEMPLATES</a>
        </li>
      </ul>

      <div class="tab-content">
        <div class="tab-pane fade" id="tab1" role="tabpanel" aria-labelledby="tab1" v-show="show_tabs === 'tab1'">
         <List />
        </div>
        <div class="tab-pane fade show active" id="tab2" role="tabpanel" aria-labelledby="tab2" v-show="show_tabs === 'tab2'">
         <Templates />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import List from "@/views/sms/List.vue";
import Templates from "@/views/sms/Templates.vue";
import {get} from "@/utils/httpRequest";
export default {
  name: "Index",
  components: {
    List,
    Templates
  },
  data: () => ({
    show_tabs: "tab2",
    balance: null
  }),
  mounted() {
    this.getBalance()
  },
  methods: {
    selectTabs(tab){
      console.log(tab)
      this.show_tabs = tab
    },
    async getBalance(){
      const {data} = await get('sms/get-zadarma-balance')
      this.balance = `${data.balance} ${data.currency}`
    }
  }
}
</script>

<style scoped>

</style>
