<template>
<div class="row mt-4">
  <div class="col">
    <table class="table table-bordered align-items-center table-sm">
      <thead>
      <tr>
        <td colspan="4">
          <h6>Transfers Notifications List</h6>
        </td>
      </tr>
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Description</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Icon</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Percentage</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Options</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, key) in arrayNotifications" :key="key">
        <td>
          <p class="text-xs font-weight-bold mb-0">{{item.title}}</p>
        </td>
        <td>
          <p class="text-xs font-weight-bold mb-0">{{item.description}}</p>
        </td>
        <td>
          <p class="text-xs font-weight-bold mb-0">{{item.icon}}</p>
        </td>
        <td>
          <p class="text-xs font-weight-bold mb-0">{{item.type}}</p>
        </td>
        <td>
          <p class="text-xs font-weight-bold mb-0">{{item.percentage}}</p>
        </td>
        <td>
          <button class="btn bg-gradient-success btn-sm" @click="showNotification(item)">Show</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="col">
    <div class="row">
      <div class="col">
        <label>Title</label>
        <input type="text" class="form-control" v-model="notification.title">
        <label>Description</label>
        <input type="text" class="form-control" v-model="notification.description">
        <label>Icon</label>
        <select class="form-control" name="icon_type" id="icon_type" v-model="notification.icon">
          <option value="success">Success</option>
          <option value="info">Info</option>
          <option value="danger">Error</option>
        </select>
        <button  class="btn bg-gradient-success mt-4 w-100" @click="createNotifications"> Create transfer alert notification</button>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col">
        <label>Title (required)</label>
        <input type="text" class="form-control" v-model="notification_bar.title">
        <label>Description (required)</label>
        <input type="text" class="form-control" v-model="notification_bar.description">
        <label>Account</label>
        <select class="form-control"
                name="icon_type"
                id="icon_type"
                v-model="notification_bar.account_id"
                v-for="(item, key) in arrayAccounts" :key="key">
          <option :value="item._id">{{item.bank}}</option>
        </select>
        <div class="row">
          <div class="col">
            <label>Amount</label>
            <input type="text" class="form-control" v-model="notification_bar.amount">
          </div>
          <div class="col">
            <label>Percentage (required)</label>
            <input type="text" class="form-control" v-model="notification_bar.percentage">
          </div>
        </div>
        <button  class="btn bg-gradient-success mt-4 w-100" @click="createBarNotifications"> Create error progress bar notification</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {get, post} from "@/utils/httpRequest";

export default {
  name: "Notifications",
  data: () => ({
    user_id: null,
    arrayNotifications: [],
    arrayAccounts: [],
    notification: {
      title: '',
      description: '',
      icon: '',
      type: 'alert',
    },
    notification_bar: {
      title: '',
      description: '',
      amount: 0,
      percentage: 0,
      account_id: '',
      type: 'progress',
    }
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getNotifications();
    this.getAccounts();
  },
  methods:{
    async getAccounts(){
      const {data} = await get(`bank-accounts/get-all-by-user?id=${this.user_id}`)
      this.arrayAccounts = data
    },
    async getNotifications(){
        const {data} = await get(`transfer-notifications/all-by-user?id=${this.user_id}`)
      this.arrayNotifications = data
    },
    async createNotifications(){
      if(this.notification.title !== '' && this.notification.description !== ''){
        await post("transfer-notifications/create",{...this.notification, user: this.user_id})
        this.notification.title = ''
        this.notification.description = ''
        await this.getNotifications()
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },
    async createBarNotifications(){
      if(this.notification_bar.title !== '' && this.notification_bar.description !== '' && this.notification_bar.percentage> 0){
        await post("transfer-notifications/create-bar",{...this.notification_bar, user: this.user_id})
       this.notification_bar = {
         title: '',
         description: '',
         amount: 0,
         percentage: 0,
         account_id: '',
         type: 'progress',
       }
        await this.getNotifications()
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },
    async showNotification(item){
      this.$swal({
        title: item.title,
        html: item.description,
        icon: item.icon,
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
}
</script>

<style scoped>

</style>
