<template>
  <table class="table table-bordered align-items-center table-sm mt-4">
    <thead>
    <tr>
      <td colspan="4">
        <h6>Notes List</h6>
      </td>
    </tr>
    <tr>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Note</th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(item, key) in arrayNotes" :key="key">
      <td>
        <p class="text-xs font-weight-bold mb-0">{{item.description}}</p>
      </td>
      <td>
        <p class="text-xs font-weight-bold mb-0">{{$filters.formatDate(item.created_at)}}</p>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {get} from "@/utils/httpRequest";

export default {
  name: "Notes",
  data: () => ({
    user_id: null,
    arrayNotes: []
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getNotes()
  },
  methods:{
    async getNotes(){
      const {data} = await get(`notes/by-user?id=${this.user_id}`)
      this.arrayNotes = data
    },
  }
}
</script>

<style scoped>

</style>
