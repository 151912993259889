import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import Users from "@/views/Users/index.vue";
import UsersCreate from "@/views/Users/create.vue";
import UsersCreateLeads from "@/views/Leads/createLeads.vue";
import UsersDetail from "@/views/Users/detail.vue";
import AgentsDetail from "@/views/Users/agent_detail.vue";
import Transactions from "@/views/Transactions/index.vue";
import Commissions from "@/views/Commissions/index.vue";
import Leads from "@/views/Leads/index.vue";
import LeadsAgent from "@/views/Leads/indexAgent.vue";
import Clients from "@/views/Clientes/index.vue";
import Logs from "@/views/Logs/Index.vue";
import Login from "@/views/SignIn.vue";
import Sms from "@/views/sms/Index.vue";
import Config from "@/views/Config/Index.vue";
import authGuard from "@/router/authGuard";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
    meta: {
      auth: false,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      auth: true,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: Dashboard,
  },
  {
    path: "/users",
    name: "users",
    meta: {
      auth: true,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: Users,
  },
  {
    path: "/users-create",
    name: "users-create",
    meta: {
      auth: true,
      roles: ['administrator']
    },
    component: UsersCreate
  },
  {
    path: "/users-create-lead",
    name: "users-create-lead",
    meta: {
      auth: true,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: UsersCreateLeads
  },
  {
    path: "/users-view",
    name: "users-view",
    meta: {
      auth: true,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: UsersDetail
  },
  {
    path: "/agents-view",
    name: "agents-view",
    meta: {
      auth: true,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: AgentsDetail
  },
  {
    path: "/transactions",
    name: "transactions",
    meta: {
      auth: true,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: Transactions,
  },
  {
    path: "/commissions",
    name: "commissions",
    meta: {
      auth: true,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: Commissions,
  },
  {
    path: "/leads",
    name: "leads",
    meta: {
      auth: true,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: Leads,
  },
  {
    path: "/leads-ftd",
    name: "leads-ftd",
    meta: {
      auth: true,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: LeadsAgent
  },
  {
    path: "/leads-retention",
    name: "leads-retention",
    meta: {
      auth: true,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: Clients,
  },
  {
    path: "/sms",
    name: "sms",
    meta: {
      auth: true,
      roles: ['agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: Sms,
  },
  {
    path: "/config",
    name: "config",
    meta: {
      auth: true,
      roles: ['administrator']
    },
    component: Config,
  },
  {
    path: "/logs",
    name: "logs",
    meta: {
      auth: true,
      roles: ['agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: Logs,
  },
  {
    path: "/login",
    name: "login",
    meta: {
      auth: false,
      roles: ['agent_ftd', 'agent_retention','supervisor_ftd','supervisor_retention','administrator']
    },
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});
router.beforeEach(authGuard);
export default router;
